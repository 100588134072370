import Header from "components/Common/Header";
import Sub from "components/Common/Sub";
import {
  MoneSection1,
  MoneSection2,
  MoneSection3,
  MoneSection4,
  MoneSection5,
  MoneSection6,
} from "components/Monetize";
import Footer from "components/Common/Footer";

const Monetize = () => {
  return (
    <>
      <Header />
      <Sub
        title="images/pc/monetize_top.png"
        bg="images/pc/img2-1.png"
        m_bg="images/mobile/img2-1.png"
      />
      <MoneSection1 />
      <MoneSection2 />
      <MoneSection3 />
      <MoneSection4 />
      <MoneSection5 />
      <MoneSection6 />
      <Footer />
    </>
  );
};

export default Monetize;
