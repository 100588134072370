/** @jsxImportSource @emotion/react */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { section6Style } from './styled';
import 'swiper/css';
import 'swiper/css/pagination';

interface dataProps {
  sub: string;
  title: string;
  text: string;
  img: string;
}

const MoneSection2 = () => {
  const data: dataProps[] = [
    {
      sub: '어떻게 사용하나요?',
      title: '매체계약과/n인벤토리 구좌 확정',
      text: '커머스 운영타입 (리워드형/논리워드형)/n연결 인벤토리 유저 세그먼트를 협의하여/n매체 내 쇼핑 서비스 운영 전략을 제시합니다.',
      img: 'images/pc/monetize_1.png',
    },
    {
      sub: '어떻게 판매되나요?',
      title: '매체특성에 맞는/n쇼핑몰을 제작',
      text: '매체 UI/UX와 일관된 쇼핑몰을 제작하고/n방문자 성향에 따른 최적의 상품을 소싱합니다./n고객이 필요한 상품을 편리하게 구매할 수 있는/n환경을 만들어 보세요.',
      img: 'images/pc/monetize_2.png',
    },
    {
      sub: '어떻게 확인하나요?',
      title: '쇼핑몰 운영과/n실시간 모니터링 제공',
      text: '매체별 전담 MD가 상품 업데이트, 정기적인 /n 기획전 등 본격적인 쇼핑몰 운영을 시작합니다./n파트너 센터에서 실시간 구매 데이터를 /n확인할 수 있습니다.',
      img: 'images/pc/monetize_3.png',
    },
    {
      sub: '어떻게 정산되나요?',
      title: 'CPS 정산 진행',
      text: '매월 구매확정 분에 대해 CPS 수수료를 정산합니다./n고객과의 접점을 확장하여 보다 높은 매출을/n달성해보세요.',
      img: 'images/pc/monetize_4.png',
    },
  ];
  return (
    <div css={section6Style}>
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}">${index + 1}</span>`;
          },
        }}
      >
        {data.map((card) => (
          <SwiperSlide>
            <ul className="container">
              <li>
                <h5>{card.sub}</h5>
                <h3>
                  {card.title.split('/n').map((line) => {
                    return <div>{line}</div>;
                  })}
                </h3>
                <p>
                  {card.text.split('/n').map((line) => {
                    return <div>{line}</div>;
                  })}
                </p>
              </li>
              <li>
                <img src={card.img} alt="content " title="content" />
              </li>
            </ul>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MoneSection2;
