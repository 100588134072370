import { css } from '@emotion/react';

export const section3Style = css`
  width: 100%;
  padding: 140px 0px;
  display: flex;
  justify-content: center;
  div:first-of-type {
    width: 733px;
    padding-top: 60px;
    margin-right: 60px;
    img {
      width: 100%;
    }
  }
  div:last-of-type {
    margin-right: 30px;
    padding-top: 135px;
    h3 {
      font-size: 48px;
      line-height: 62px;
      font-weight: 700;
    }
    p {
      font-size: 19px;
      margin-top: 35px;
      line-height: 35px;
      color: #62626a;
      padding-bottom: 5%;
    }
  }

  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 80px 0px;
    width: 100%;
    display: block;
    div:first-of-type {
      width: 280px;
      margin: 0 auto;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    div:last-of-type {
      padding: 0;
      margin-right: 0px;
      h3 {
        font-size: 24px;
        margin-top: 15px;
        margin-bottom: 15px;
        line-height: 30px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 0;
        padding-bottom: 0;
      }
    }
  }
`;
