/** @jsxImportSource @emotion/react */
import { section5Style } from './styled';

// interface dataProps {
//   img: string;
//   title: string;
//   text: string;
//   m_img: string;
// }

const MoneSection1 = () => {
  const data = [
    {
      img: 'images/pc/img2-2.png',
      m_img: 'images/mobile/img2-2.png',
      title: '매체 중심 최적의 상품 구성',
      text: '다양한 상품 구성 뿐 아니라 매체 속성에/n맞는 상품을 선별하여 구성합니다.',
    },
    {
      img: 'images/pc/img2-3.png',
      m_img: 'images/mobile/img2-3.png',
      title: '안정적인 수익 창출',
      text: '자사 매체에 연동된 쇼핑몰에서 발생한/n매출의 15% 이상을 수익으로 지급합니다.',
    },
    {
      img: 'images/pc/img2-4.png',
      m_img: 'images/mobile/img2-4.png',
      title: '브랜드 로고 등 커스터마이징',
      text: '매체사의 브랜드명을 유지한 쇼핑몰을 제공하여/n브랜드 아이덴티티를 유지할 수 있습니다.',
    },
  ];
  return (
    <div css={section5Style}>
      <ul className="container">
        {data.map((card) => (
          <li>
            <img
              src={card.img}
              alt="content"
              title="content"
              className="pc_con"
            />
            <img
              src={card.m_img}
              alt="content"
              title="content"
              className="m_con"
            />
            <h3>{card.title}</h3>
            <p>
              {card.text.split('/n').map((line) => {
                return <div>{line}</div>;
              })}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MoneSection1;
