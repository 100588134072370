import { css } from '@emotion/react';

export const popupStyle = css`
  .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 111;
  }
  form {
    width: 385px;
    height: auto;
    position: fixed;
    top: 5%;
    bottom: 0;
    left: 33%;
    background: #fff;
    padding: 48px 62px;
    z-index: 11111;
    .form_header {
      display: flex;
      justify-content: space-between;
      h1 {
        font-size: 24px;
        font-weight: 800;
      }
      button {
        width: 30px;
        height: 30px;
        right: -25px;
        top: -15px;
        position: relative;
        cursor: pointer;
        background: none;
        border: none;
      }
    }
    .form_header button::before {
      content: '';
      display: block;
      width: 32px;
      height: 1px;
      position: absolute;
      left: 3px;
      top: 10px;
      transform: rotate(135deg);
      background: #000000;
    }
    .form_header button::after {
      content: '';
      display: block;
      width: 32px;
      height: 1px;
      position: absolute;
      left: 3px;
      top: 10px;
      transform: rotate(45deg);
      background: #000000;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    input {
      margin-top: 10px;
      height: 50px;
      font-size: 16px;
      font-family: inherit;
      text-indent: 20px;
    }
    input:focus {
      outline: 2px solid #000;
    }
    textarea {
      width: 100%;
      height: 116px;
      padding: 4% 5%;
      border: 1px solid #000;
      font-size: 16px;
      font-family: inherit;
      margin-top: 10px;
      box-sizing: border-box;
    }
  }
  .input_check {
    input {
      visibility: hidden;
      left: -3px;
      top: 23%;
      position: absolute;
      zoom: 2;
    }
    label {
      padding: 24px 0 24px 40px;
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      color: #303034;
    }
    label::before {
      content: '';
      display: block;
      width: 7px;
      height: 14px;
      border-bottom: 1px solid #979797;
      border-right: 1px solid #979797;
      transform: rotate(45deg);
      position: absolute;
      left: 10px;
      top: 35%;
    }
    label::after {
      content: '';
      display: block;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 1px solid #979797;
      position: absolute;
      left: 0%;
      top: 50%;
      margin-top: -13.5px;
    }
    label:hover::after,
    label:active::after {
      border: 1px solid #000;
    }
    label:hover::before,
    label:active::before {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
    }
    input:checked + label::after {
      border: 1px solid #000;
    }
    input:checked + label::before {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
    }
  }
  .notice {
    padding: 16px 22px;
    font-size: 12px;
    line-height: 22px;
    background: #f8f8f8;
    border: 1px solid #dddddd;
  }
  .form_button {
    margin: 5% auto 0 auto;
    width: 174px;
    height: 50px;
    border: 1px solid #000;
    font-size: 15px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    display: block;
    transition: 0.3s;
    background: #fff;
    border-radius: 30px;
  }
  .form_button:hover {
    background: #000;
    color: #fff;
  }

  @media screen and (max-width: 1080px) {
    form {
      width: 76% !important;
      height: auto;
      padding: 27px 33px !important;
      transform: translate(-30%, 0%);
      .form_header {
        h1 {
          font-size: 15px;
          font-weight: bold;
        }
      }
      ul {
        input {
          border: 1px solid #000;
          height: 7px !important;
          padding: 11px;
          font-size: 10px;
          text-indent: 0px;
        }
        textarea {
          font-size: 10px;
          padding: 11px;
          height: 94px;
        }
      }
      .input_check {
        label {
          padding: 15px 0 15px 32px;
          font-size: 12px;
        }
        label::before {
          height: 9.5px;
          width: 4px;
          left: 9px;
          top: 32%;
        }
        label::after {
          width: 20px;
          height: 20px;
          margin-top: -12px;
        }
      }
      .notice {
        padding: 9px;
        font-size: 10px;
        font-weight: normal;
      }
      .form_button {
        width: 125px;
        height: 38px;
        font-size: 12px;
        line-height: 35px;
      }
    }
  }
`;
