import { css, jsx } from "@emotion/react";

export const subStyle = css`
  .pc_con {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 140px 0px;
    button {
      box-shadow: none;
      background: inherit;
      cursor: pointer;
      border: none;
      display: block;
      margin-top: 56px;
      width: 165px;
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    img {
      width: 100%;
    }
  }
`;
