/** @jsxImportSource @emotion/react */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { section6Style } from '../../Monetize/MoneSection2/styled';
import 'swiper/css';
import 'swiper/css/pagination';

interface dataProps {
  sub: string;
  title: string;
  text: string;
  img: string;
}

const SellSection2 = () => {
  const data: dataProps[] = [
    {
      sub: '어떻게 시작하나요?',
      title: '셀러드 파트너로/n등록하기',
      text: '셀러드 파트너 센터에서 입점 신청을 해주세요./n신청이 완료되면 담당 MD 배정 및 /n심사가 진행됩니다.',
      img: 'images/pc/selling_1.png',
    },
    {
      sub: '어떻게 시작하나요?',
      title: '노출 매체를 선정하고/n상품을 등록',
      text: '담당 MD와 협의하여 노출 구좌와 상품을 선정합니다./n매체별 성격에 따라 전시 여부, 주력 상품, 가격 정책을/n자유롭게 문의할 수있습니다.',
      img: 'images/pc/selling_2.png',
    },
    {
      sub: '어떻게 진행되나요?',
      title: '선택한 매체에/n상품을 전시',
      text: '매체별 쇼핑영역에 상품이 노출되며/n본격적인 판매가 시작됩니다.',
      img: 'images/pc/selling_3.png',
    },
    {
      sub: '어떻게 정산되나요?',
      title: 'CPS 정산 진행',
      text: '매월 구매확정 분에 대해 CPS 수수료를 정산합니다./n고객과의 접점을 확장하여 보다 높은 매출을/n달성해보세요.',
      img: 'images/pc/selling_4.png',
    },
  ];
  return (
    <div css={section6Style}>
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}">${index + 1}</span>`;
          },
        }}
      >
        {data.map((card) => (
          <SwiperSlide>
            <ul className="container">
              <li>
                <h5>{card.sub}</h5>
                <h3>
                  {card.title.split('/n').map((line) => {
                    return <div>{line}</div>;
                  })}
                </h3>
                <p>
                  {card.text.split('/n').map((line) => {
                    return <div>{line}</div>;
                  })}
                </p>
              </li>
              <li>
                <img src={card.img} alt="content " title="content image" />
              </li>
            </ul>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SellSection2;
