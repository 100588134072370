import { css } from '@emotion/react';

export const footerStyle = css`
  padding: 140px 0px;
  background: url('images/pc/img1-8.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  h3 {
    font-size: 45px;
    margin-bottom: 40px;
  }
  b {
    font-weight: bold;
  }
  p {
    margin-bottom: 40px;
    font-size: 23px;
  }
  button {
    font-size: 17px;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    border-radius: 30px;
    width: 210px;
    height: 65px;
    line-height: 60px;
    margin: 0 14px;
    padding: 0;
  }
  button:hover {
    background: #fff;
    color: #303034;
  }

  @media screen and (max-width: 1080px) {
    padding: 80px 0px;
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
      line-height: 30px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 40px;
      margin-top: 0;
    }
    button {
      width: 150px;
      height: 45px;
      margin: 0 7px;
      font-size: 13.5px;
      padding: 0;
      line-height: 46px;
    }
    button:last-child {
      margin-top: 20px;
    }
  }
`;

export const footerInfoStyle = css`
  background: #111111 !important;
  padding: 30px 0px;
  line-height: 30px;
  color: #fff;
  p {
    text-align: left;
    user-select: auto;
    font-size: 13px;
    padding: 0 10%;
  }

  @media screen and (max-width: 1080px) {
    background: #3e4454 !important;
    padding: 20px 20px;
    p {
      font-size: 11px;
      line-height: 20px;
      text-align: left;
      padding: 0;
    }
  }
`;
