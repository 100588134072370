/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PopUp from 'components/PopUp';
import { useInquiry } from 'store/useInquiry';
import { headerStyle, mobileHeaderStyle } from './styled';

const Header = () => {
  const [scrollY, setScrollY] = useState(0); //스크롤값 상태 관리
  const [showBg, setShowBg] = useState(false); // 헤더배경 노출 상태 관리
  const [isOpen, setIsOpen] = useState(false); //모바일 햄버거 메뉴 상태 관리
  const { popupOpen, openPopup, closePopup } = useInquiry((state) => state); // 팝업 전역변수

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if (scrollY > 80) {
      setShowBg(true);
    } else {
      setShowBg(false);
    }
  };
  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow);
    };
  });
  // 스크롤 기준별로 헤더배경 노출 & 비노출

  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setScrollY(0);
    setShowBg(false);
  };
  // 스크롤 탑으로 이동

  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  // 모바일 토글 메뉴 노출

  return (
    <div css={headerStyle}>
      <header className="pc_con">
        <div
          css={{
            background: showBg ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
          }}
          className="header_container"
        >
          <h1 className="logo">
            <Link to="/">
              <img
                src="/images/pc/logo.png"
                alt="logo"
                title="logo"
                onClick={handleTop}
              />
            </Link>
          </h1>
          <nav>
            <ul>
              <li>
                <Link to="/monetize" onClick={handleTop}>
                  제휴&middot;수익화
                </Link>
              </li>
              <li>
                <Link to="/selling" onClick={handleTop}>
                  유통&middot;판매
                </Link>
              </li>
              <li onClick={openPopup}>
                <p>문의하기</p>
              </li>
            </ul>
          </nav>
          <div className="button_box">
            <button
              onClick={() => {
                window.open('http://gscm.sellerd.co.kr', '_blank');
              }}
            >
              판매사 로그인
            </button>
          </div>
        </div>
      </header>
      <header className="m_con" css={mobileHeaderStyle}>
        <div
          css={{
            background: showBg ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
          }}
          className="m_header_container"
        >
          <h1 className="logo">
            <Link to="/">
              <img
                src="/images/pc/logo.png"
                alt="logo"
                title="logo"
                onClick={handleTop}
              />
            </Link>
          </h1>
          <h2>
            <img
              src="/images/mobile/hamberger.png"
              alt="menu"
              title="menu"
              onClick={() => {
                toggleMenu();
              }}
            />
          </h2>
          <nav className={isOpen ? 'showNav' : ''}>
            <div className="nav_bg" onClick={() => toggleMenu()} />
            <ul onClick={handleTop}>
              <Link to="/monetize">
                <li>제휴&middot;수익화</li>
              </Link>

              <Link to="/selling">
                <li>유통&middot;판매</li>
              </Link>

              <li onClick={openPopup}>문의하기</li>
              <li>
                <a href="/file/sellerd.pdf" download>
                  소개서 다운로드
                </a>
              </li>
              <li
                className="login"
                onClick={() => {
                  window.open('http://gscm.sellerd.co.kr', '_blank');
                }}
              >
                판매사 로그인
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <PopUp open={popupOpen} close={closePopup} />
    </div>
  );
};

export default Header;
