/** @jsxImportSource @emotion/react */
import { subStyle } from './styled';
import { useInquiry } from '../../../store/useInquiry';

interface subProps {
  bg: string;
  title: string;
  m_bg: string;
}

const Sub = (data: subProps) => {
  const { bg, title, m_bg } = data;
  const { openPopup } = useInquiry((state) => state); // 팝업 전역변수

  return (
    <div css={subStyle}>
      <div style={{ backgroundImage: `url(${bg})` }} className=" pc_con">
        <div className="container">
          <img src={title} alt="main title" title="main title" />
          <button type="button" onClick={openPopup}>
            <img src="images/pc/button.png" alt="button" title="button" />
          </button>
        </div>
      </div>
      <div className="m_con ">
        <img src={m_bg} alt="main bg" title="main bg" />
      </div>
    </div>
  );
};

export default Sub;
