/** @jsxImportSource @emotion/react */
import { section9Style } from './styled';

const SellSection5 = () => {
  return (
    <div css={section9Style}>
      <div className="container">
        <div>
          <img
            src="images/pc/img3-8.png"
            alt="content "
            title="content image"
          />
        </div>
        <div>
          <h3>매체사 통합 관리</h3>
          <p>
            단 한번의 상품등록으로 sellerd 네트워크로 연결된
            <br />
            다수 커머스 채널에서 상품을 관리할 수 있습니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SellSection5;
