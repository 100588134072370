/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import { section2Style } from './styled';

const Section2 = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  // 스크롤 탑으로 이동

  return (
    <div css={section2Style}>
      <div>
        <h3>매체 파트너</h3>
        <p>쇼핑 서비스로 새로운 수익화를 경험하세요.</p>
        <Link to="/monetize">
          <button onClick={handleTop} type="button">
            더 알아보기
          </button>
        </Link>
      </div>
      <div>
        <h3>판매 파트너</h3>
        <p>새로운 고객을 찾아 매출 상승을 경험하세요.</p>
        <Link to="/selling">
          <button onClick={handleTop} type="button">
            더 알아보기
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Section2;
