import { css, jsx } from "@emotion/react";
import { selectColor } from "../../Selling/SellSection4";

export const section1Style = css`
  padding: 160px 0;
  // text-align: ${selectColor} ? center: left;
  text-align: center;
  div {
    h2 {
      font-size: 45px;
      font-weight: bold;
      padding-bottom: 44px;
    }
    p {
      font-size: 19px;
      line-height: 35px;
    }
    div {
      text-align: center;
      margin-top: 62px;
      img {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    padding: 80px 0px;
    font-weight: bold;
    div {
      h2 {
        font-size: 24px;
        margin-bottom: 15px;
        padding-bottom: 0px;
        line-height: 26px;
      }
      p {
        font-size: 14px;
        line-height: 22px;

        font-weight: normal;
      }
      div {
        text-align: center;
        width: 280px;
        margin: 40px auto 0 auto;
        img {
          width: 100%;
          margin-top: 40px;
        }
      }
    }
  }
`;
