import Header from "components/Common/Header";
import Sub from "components/Common/Sub";
import {
  SellSection1,
  SellSection2,
  SellSection3,
  SellSection4,
  SellSection5,
  SellSection6,
} from "components/Selling";
import Footer from "components/Common/Footer";

const Selling = () => {
  return (
    <>
      <Header />
      <Sub
        title="images/pc/selljng_top.png"
        bg="images/pc/img3-1.png"
        m_bg="images/mobile/img3-1.png"
      />
      <SellSection1 />
      <SellSection2 />
      <SellSection3 />
      <SellSection4 />
      <SellSection5 />
      <SellSection6 />
      <Footer />
    </>
  );
};
// 집가서 수정
export default Selling;
