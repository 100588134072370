/** @jsxImportSource @emotion/react */
import axios from 'axios';
import { popupStyle } from './styled';

interface popupProps {
  open: boolean;
  close: () => void;
}
// 팝업창 열고 닫고 타입 지정

const PopUp = (props: popupProps) => {
  const { open, close } = props;
  // 헤더에서 내려준 상태, 함수 props로 받음

  const check = (re: any, what: any, message: any) => {
    if (re.test(what)) {
      return true;
    }
    alert(message);
  };

  const send = (e: any) => {
    e.preventDefault();
    let text = '';
    // const subject = '';
    const re2 =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    const regPhone = /(01[0|1|6|9|7])[-](\d{3}|\d{4})[-](\d{4}$)/;
    const name = (document.getElementById('partners_name') as HTMLInputElement)
      .value;
    const company_name = (
      document.getElementById('partners_company') as HTMLInputElement
    ).value;
    const phone = (
      document.getElementById('partners_phone') as HTMLInputElement
    ).value;
    const email = (
      document.getElementById('partners_email') as HTMLInputElement
    ).value;
    const detail = (
      document.getElementById('partners_detail') as HTMLInputElement
    ).value;
    const agree = (
      document.getElementById('partners_check') as HTMLInputElement
    ).checked;

    if (name.length <= 0 || email.length <= 0 || phone.length <= 0) {
      alert('필수값을 입력해주세요');
      return;
    }

    if (!check(re2, email, '적합하지 않은 이메일 형식입니다.')) {
      return false;
    }

    if (!check(regPhone, phone, '적합하지 않은 휴대폰 번호 형식입니다.')) {
      return false;
    }

    if (agree !== true) {
      alert('개인정보 수집 이용에 동의합니다. (필수)');
      return;
    }

    if (name.length > 0) {
      text += name;
    }
    text += `\n회사이름: ${company_name}`;
    text += `\n전화 번호: ${phone}`;
    text += `\n이메일: ${email}`;
    text += `\n문의 내용: ${detail}`;
    // const data = {
    //   subject,
    //   text,
    // };
    axios
      .post(
        'https://api.sellerd.co.kr/api/v1/message',
        JSON.stringify({
          from: email,
          to: 'bizcenter@genieworks.net',
          contentType: 'HTML',
          messageType: 'EMAIL',
          title: '문의 메일 드립니다.',
          content: text,
        }),
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
      .then((response) => {
        console.log('성공', response);
        alert('메일이 전송되었습니다.');
        close();
        // 응답처리
      })
      .catch((error) => {
        console.log('실패', error);
        alert('메일이 전송되었습니다.');
        close();
        // 예외처리
      });
  };

  return (
    <div css={popupStyle}>
      {open ? (
        <>
          <div className="bg" />
          <form onSubmit={send}>
            <div className="form_header">
              <h1>파트너 문의하기</h1>
              <button onClick={close} type="button" aria-label="close button" />
            </div>
            <ul>
              <input
                type="text"
                placeholder="이름*"
                name="name"
                id="partners_name"
              />
              <input
                type="text"
                placeholder="기업명"
                name="company"
                id="partners_company"
              />
              <input
                type="text"
                placeholder="이메일*"
                name="email"
                id="partners_email"
              />
              <input
                type="text"
                placeholder="전화번호(-포함)*"
                name="phone"
                id="partners_phone"
              />
              <textarea
                id="partners_detail"
                name="detail"
                placeholder="세부 문의 내용을 기재해 주시면 담당자 확인 후 연락드리겠습니다."
              />
            </ul>
            <div className="input_check">
              <input type="checkbox" name="agree" id="partners_check" />
              <label htmlFor="partners_check">
                개인정보 수집 이용에 동의합니다. (필수)
              </label>
            </div>
            <div className="notice">
              지니웍스에서는 아래와 같은 개인 정보를 수집하고 있습니다.
              <br />
              수집 목적: 고객 지원
              <br />
              수집 항목: 이름, 기업명, 이메일, 전화번호
              <br />
              보유 및 이용 기간: 목적 달성 시 즉시 파기
            </div>
            <button className="form_button" type="submit" id="partners_button">
              문의 등록
            </button>
          </form>
        </>
      ) : null}
    </div>
  );
};

export default PopUp;
