/** @jsxImportSource @emotion/react */
import { section5Style } from '../../Monetize/MoneSection1/styled';

interface dataProps {
  img: string;
  title: string;
  text: string;
}

const SellSection1 = () => {
  const data: dataProps[] = [
    {
      img: 'images/pc/img3-2.png',
      title: '상품 접근성 향상',
      text: '커머스 네트워크로 연동된 매체에/n상품을 노출시켜 소비자 접근성을 높입니다.',
    },
    {
      img: 'images/pc/img3-3.png',
      title: '매출 성장',
      text: '5,000여 매체에서 발생한 구매 데이터를/n기반으로 최적의 판매 전략을 수립합니다.',
    },
    {
      img: 'images/pc/img3-4.png',
      title: '편리한 운영',
      text: '상품 노출, 할인율, 수수료 등 매체별로/n독립적인 상품 운용이 가능합니다.',
    },
  ];
  return (
    <div css={section5Style}>
      <ul className="container">
        {data.map((card) => (
          <li>
            <img src={card.img} alt="content " title="content image" />
            <h3>{card.title}</h3>
            <p>
              {card.text.split('/n').map((line) => {
                return <div>{line}</div>;
              })}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SellSection1;
