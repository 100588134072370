/** @jsxImportSource @emotion/react */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { AppStyle } from './styles/GlobalStyles';
import Home from './pages/Home';
import Monetize from './pages/Monetize';
import Selling from './pages/Selling';

function App() {
  return (
    <div className="App" css={AppStyle}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/monetize" element={<Monetize />} />
          <Route path="/selling" element={<Selling />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
