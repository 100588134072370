/** @jsxImportSource @emotion/react */
import { section1Style } from './styled';

const Section1 = () => {
  return (
    <div css={section1Style}>
      <div className="container">
        <h2>
          이제 고객을 찾아가는
          <br className="m_con" /> 쇼핑의 시대입니다.
        </h2>
        <p>
          새로운 수익 창출 모델로 쇼핑몰을 시작하고 싶은데,
          <br className="m_con" /> 상품소싱, 재고관리, 판매관리는 어떻게 해야
          할까?
          <br />
          판매처 선정 및 계약관리까지 셀러드에서 시작하세요.
        </p>
        <div>
          <img
            src="images/pc/img1-2.png"
            alt="content"
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img1-2.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default Section1;
