/** @jsxImportSource @emotion/react */
import React from 'react';
import { section3Style } from './styled';

const Section3 = () => {
  return (
    <div css={section3Style}>
      <div>
        <img src="images/pc/img1-5.png" alt="content " title="content image" />
      </div>
      <div>
        <h3>
          매체에 쇼핑몰을 <br />[ PLUS ] 하다
        </h3>
        <p>
          자사 매체에 쇼핑 서비스를 접목시켜 새로운 수익 모델을
          <br className="m_con" /> 창출하세요.
          <br className="pc_con" />
          고객에게 품질 높은 상품을 제공하여
          <br className="m_con" /> 지속적인 방문을 유도하고
          <br className="pc_con" />
          판매당 수수료를 통해 온라인
          <br className="m_con" /> 커머스 수수료 중 가장 높은 수익을
          <br className="pc_con" />
          기대할 수 있습니다.
        </p>
      </div>
    </div>
  );
};

export default Section3;
