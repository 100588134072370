/** @jsxImportSource @emotion/react */
import { section1Style } from '../../Home/Section1/styled';

const MoneSection3 = () => {
  return (
    <div css={section1Style}>
      <div className="container">
        <h2>자연스러운 쇼핑 고객 전환</h2>
        <p>
          셀러드 커머스 플랫폼으로 유저에게 쇼핑 혜택을
          <br className="m_con" /> 직접 제공함으로써 고객을 유료 구매 유저로
          자연스럽게
          <br className="m_con" /> 전환시킬 수 있습니다.
        </p>
        <div>
          <img
            src="images/pc/img2-6.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img2-6.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default MoneSection3;
