import create from 'zustand';

interface PopUpState {
  popupOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
}

export const useInquiry = create<PopUpState>((set) => ({
  popupOpen: false,
  openPopup: () => set({ popupOpen: true }),
  closePopup: () => set({ popupOpen: false }),
}));
