import Header from 'components/Common/Header';
import Main from 'components/Common/Main';
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
} from 'components/Home';
import Footer from 'components/Common/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <Main
        title="images/pc/img1-9.png"
        bg="images/pc/img1-1.png"
        m_title="images/mobile/home_top_txt.png"
        m_bg="images/mobile/img1-1.png"
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  );
};

export default Home;
