import { css } from '@emotion/react';

export const section5Style = css`
  ul {
    display: flex;
    justify-content: space-between;
    padding: 130px;
    width: 1200px;
    li {
      width: calc(100% / 3);
      text-align: center;
      img {
        margin: 0 auto;
      }
      h3 {
        margin-top: 50px;
        font-size: 25px;
        font-weight: 700;
        color: #303034;
        margin-bottom: 10px;
      }
      p {
        color: #62626a;
        font-size: 17px;
        line-height: 28px;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    padding: 80px 0;
    .container {
      width: 100%;
    }
    ul {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      li {
        margin-top: 60px;
        width: 100%;
        img {
          width: 70px;
          margin: 0 auto 20px;
        }
        h3 {
          margin-top: 20px;
          font-size: 21px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
      li:first-of-type {
        margin-top: 0;
      }
    }
  }
`;
