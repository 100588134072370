/** @jsxImportSource @emotion/react */

import { footerStyle, footerInfoStyle } from './styled';
import { useInquiry } from '../../../store/useInquiry';

const Footer = () => {
  const { openPopup } = useInquiry((state) => state); // 팝업 전역변수
  return (
    <>
      <div css={footerStyle}>
        <h3>
          네트워크 커머스 플랫폼,<b>셀러드</b>
        </h3>
        <p>셀러드로 새로운 수익 창출을 준비하세요</p>
        <button onClick={openPopup} type="button">
          문의하기
        </button>
        <a href="/file/sellerd.pdf" download>
          <button type="button">소개서 다운로드</button>
        </a>
        <button
          onClick={() => {
            window.open('http://guide.sellerd.co.kr', '_blank');
          }}
          type="button"
        >
          파트너 가이드
        </button>
      </div>
      <div css={footerInfoStyle}>
        <p>
          주식회사 지니웍스 &nbsp;대표이사: 이앙 &nbsp; &nbsp;| &nbsp;
          &nbsp;사업자 등록번호:551-88-00073 &nbsp; &nbsp;
          <br className="m_con" />
          서울특별시 강남구 논현로 653, 2층&nbsp; &nbsp;| &nbsp;
          통신판매업신고:강남 01966호 &nbsp; &nbsp;| &nbsp;
          &nbsp;고객센터:1800-1011
        </p>
        <p>Copyright Genieworks.Inc &nbsp;All Right Reserved</p>
      </div>
    </>
  );
};

export default Footer;
