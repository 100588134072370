/** @jsxImportSource @emotion/react */
import { section7Style } from './styled';

const MoneSection4 = () => {
  return (
    <div css={section7Style}>
      <div className="container">
        <div className="pc_con">
          <img
            src="images/pc/img2-7-2.png"
            alt="content "
            title="content image"
          />
        </div>
        <div>
          <h3>
            매체에 최적화된
            <br />
            쇼핑몰 운영
          </h3>
          <p>
            매체 사용자 속성을 파악하여 쇼핑몰 컨셉과 상품을
            <br className="m_con" /> 구성합니다.
            <br className="pc_con" />
            맞춤형 운영 전략으로 수익률을 극대화 합니다.
          </p>
          <div>
            <img
              src="images/pc/img2-7.png"
              alt="content "
              title="content image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneSection4;
