/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { section1Style } from '../../Home/Section1/styled';

export const selectColor = false;

const SellSection4 = () => {
  return (
    <div
      css={[
        section1Style,
        css`
          background: #3e4454;
          color: #fff;
        `,
      ]}
    >
      <div className="container">
        <h2>프리미엄 매체 연동</h2>
        <p>
          다수의 프리미엄 모바일 앱과 국내 최대 ad-network사에
          <br className="m_con" /> sellerd를 활용한 쇼핑서비스가 연동되어
          있습니다.
        </p>
        <div>
          <img
            src="images/pc/img3-7.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img3-7.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default SellSection4;
