/** @jsxImportSource @emotion/react */
import { section1Style } from '../../Home/Section1/styled';

const SellSection3 = () => {
  return (
    <div css={section1Style}>
      <div className="container">
        <h2>타겟팅 광고 효과</h2>
        <p>
          커머스 네트워크의 데이터를 기반으로 개인 맞춤형
          <br className="m_con" />
          상품을 추천합니다.
          <br className="pc_con" />
          고객에게 불필요한 상품노출을
          <br className="m_con" /> 최소화하고 쾌적한 쇼핑 환경을 제공할 수
          있습니다.
        </p>
        <div>
          <img
            src="images/pc/img3-6.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img3-6.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default SellSection3;
