/** @jsxImportSource @emotion/react */
import { section4Style } from './styled';

const Section4 = () => {
  return (
    <div css={section4Style}>
      <div className="container">
        <div>
          <h3>
            새로운 개념의
            <br />
            Network market
          </h3>
          <p>
            보유한 상품을 sellerd 네트워크를 활용하여 다수
            <br className="m_con" /> 모바일 유저에게
            <br className="pc_con" />
            동시 노출 및 판매할 수 있습니다.
            <br className="m_con" /> 오픈마켓, 종합몰, 소셜 등 정해진
            <br className="pc_con" />
            market place에서
            <br className="m_con" /> 벗어나 새로운 쇼핑 고객층을 타겟으로 매출을
            <br />
            보장 받을 수 있습니다.
          </p>
        </div>
        <div>
          <img
            src="images/pc/img1-6.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img1-6.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default Section4;
