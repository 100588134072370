import { css, jsx } from "@emotion/react";

export const mainStyle = css`
  .pc_con {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 140px 0px;
    button {
      box-shadow: none;
      background: inherit;
      cursor: pointer;
      border: none;
      display: block;
      margin-top: 67px;
      width: 165px;
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
`;
export const mobilMainStyle = css`
  @media screen and (max-width: 1080px) {
    background-color: #474c5b;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left bottom;
    text-align: center;
    padding: 140px 0px 210px 0;
    .container {
      padding: 0px;
      img {
        width: 260px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
        margin: 18px 0 25px 0;
        color: #fff;
      }
      button {
        margin: 0px auto 0;
        box-shadow: none;
        background: inherit;
        cursor: pointer;
        border: none;
        display: block;
        width: 165px;
        padding: 0;
        img {
          width: 120px;
        }
      }
    }
  }
`;
