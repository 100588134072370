import { css } from '@emotion/react';

export const section8Style = css`
  background: #f4f4f4;
  color: #000;
  padding: 322px 0;
  .container {
    display: flex;
    align-items: center;
    div:first-of-type {
      h3 {
        font-size: 48px;
        line-height: 62px;
        font-weight: bold;
      }
      p {
        font-size: 19px;
        margin-top: 35px;
        line-height: 35px;
      }
    }
    div:last-of-type {
      position: absolute;
      width: 640px;
      margin-left: 60px;
      left: 600px;
      top: -130px;
      img {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 80px 0px 0;
    .container {
      display: block;
      div:first-of-type {
        margin: auto;
        h3 {
          font-size: 24px;
          margin-bottom: 15px;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin-top: 0;
        }
      }
      div:last-of-type {
        width: 300px;
        display: block;
        position: relative;
        left: 0 !important;
        width: auto !important;
        top: 0 !important;
        margin-left: 0px;
        text-align: center;
        img {
          width: 300px;
          margin: 40px auto 40px;
        }
      }
    }
  }
`;
