import { css } from '@emotion/react';

export const headerStyle = css`
  font-weight: bold !important;
  .header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 23px 62px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 111;
    box-sizing: border-box;
    transition: 0.3s;
    font-weight: bold;
    a {
      text-decoration: none;
      color: #fff;
    }
    a:visited,
    a:active {
      text-decoration: none;
    }

    h1 {
      padding-top: 0.4%;
    }
    ul {
      display: flex;
      color: #fff;
      padding-bottom: 4px;
      li {
        font-weight: bold;
        font-size: 19px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px 25px;
        margin-left: 9px;
      }
      li:first-of-type {
        margin-left: 0;
      }
    }
    button {
      font-size: 17px;
      font-weight: 500;
      color: #fff;
      width: 135px;
      border: 1px rgba(255, 255, 255, 0.5) solid;
      border-radius: 4px;
      box-shadow: none;
      background: inherit;
      cursor: pointer;
      padding: 13px 0;
      margin-left: 16px;
    }
    button:first-of-type {
      margin: 0;
    }
    button:hover {
      background: #fff;
      color: #303034;
    }
  }
`;

export const mobileHeaderStyle = css`
  @media screen and (max-width: 1080px) {
    font-weight: bold;
    a {
      text-decoration: none;
      color: #333333;
    }
    .m_header_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 111;
      box-sizing: border-box;
      transition: 0.3s;
      padding: 20px 20px 16px 20px;
      box-sizing: border-box;
    }
    h1 img {
      height: 18px;
      margin-top: 0;
    }
    h2 img {
      height: 16px;
    }
    nav {
      display: none;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 11;
      left: 0;
      top: 0;
      .nav_bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        top: 0;
      }
      ul {
        background: #fff;
        width: 240px;
        height: 100%;
        z-index: 11;
        position: fixed;
        right: 0;
        color: #333333;
        font-size: 13px;
        li {
          text-align: left;
          padding: 19px 24px;
          cursor: pointer;
        }
        .login {
          padding: 11px 24px;
          color: #555555;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
    .showNav {
      display: block;
    }
  }
`;
