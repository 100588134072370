/** @jsxImportSource @emotion/react */
import { section1Style } from '../Section1/styled';

const Section5 = () => {
  return (
    <div css={section1Style}>
      <div className="container">
        <h2>Our Partners</h2>
        <p>
          약 900여 판매 파트너 20만개 상품을 국내 프리미엄
          <br className="m_con" /> 모바일 매체 및
          <br className="pc_con" />
          광고 네트워크 사와 연동하여
          <br className="m_con" /> 판매 중입니다.
        </p>
        <div>
          <img
            src="images/pc/img1-7.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img1-7.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default Section5;
