import { css } from '@emotion/react';

export const section2Style = css`
  width: 100%;
  display: flex;
  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 160px 0;
    height: 190px;
    text-align: center;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in;
    h3 {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
    }
    p {
      color: #fff;
      font-weight: bold;
      font-size: 19px;
      margin-top: 16px;
    }
    button {
      font-weight: 500;
      font-size: 18px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 30px;
      box-shadow: none;
      background: none;
      cursor: pointer;
      padding: 13px 60px;
      margin: 32px auto 20%;
      color: #fff;
      transition: 0.3s;
    }
    button:hover {
      background: #fff;
      color: #303034;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
    a:hover {
      color: #303034;
    }
  }
  div:first-of-type {
    background-image: url('images/pc/img1-3.png');
    background-size: cover;
    background-position: center center;
  }
  div:last-of-type {
    background-image: url('images/pc/img1-4.png');
    background-size: cover;
    background-position: center center;
  }

  @media screen and (max-width: 1080px) {
    display: block;
    div {
      width: 100%;
      height: auto;
      padding: 40px 0;
      text-align: center;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
        margin-top: 8px;
      }
      button {
        width: 99px;
        height: 30px;
        font-size: 11px;
        line-height: 30px;
        padding: 0;
        margin: 13px auto 0;
      }
      button:hover {
        background: #fff;
        color: #303034;
      }
    }
  }
`;
