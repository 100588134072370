/** @jsxImportSource @emotion/react */
import { section8Style } from './styled';

const MoneSection6 = () => {
  return (
    <div css={section8Style}>
      <div className="container">
        <div>
          <h3>파트너 지원 시스템</h3>
          <p className="pc_con">
            sellerd는 다수 매체를 통해 커머스 고객의 insight를 자체적으로
            분석합니다.
            <br />
            매체별 판매 가속 및 기술관련 대응을 위해 고객센터를 운영하고 있으며
            <br />
            실시간 구매현황을 확인할 수 있는 파트너 시스템을 제공하여 마케팅
            활동에
            <br />
            도움을 드리고 있습니다.
          </p>
          <p className="m_con">
            커머스 운영타입 (리워드형/논리워드형)
            <br />
            연결 인벤토리 유저 세그먼트를 협의하여
            <br />
            매체 내 쇼핑 서비스 운영 전략을 제시합니다.
          </p>
        </div>
        <div>
          <img
            src="images/pc/img2-9.png"
            alt="content"
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img2-10.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default MoneSection6;
