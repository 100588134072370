import { css } from '@emotion/react';

export const section6Style = css`
  padding: 190px 0;
  background: #f4f4f4;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      height: 400px;
    }
    li:first-of-type {
      padding-bottom: 196px;
      h5 {
        color: #ff5b48;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 48px;
        line-height: 62px;
        font-weight: bold;
      }
      p {
        font-size: 19px;
        margin-top: 35px;
        line-height: 35px;
      }
    }
    li:last-of-type {
      margin-left: 455px;
      position: absolute;
    }
  }
  .swiper-pagination-bullet {
    width: 34px !important;
    height: 34px !important;
    background: #fff !important;
    color: #62626a !important;
    font-weight: bold;
    line-height: 34px;
    font-size: 16px !important;
  }
  .swiper-pagination-bullet-active {
    background: #303034 !important;
    color: #fff !important;
  }
  .swiper-slide {
    width: 1230px;
  }
  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 80px 0px;
    ul {
      display: block;
      li:first-of-type {
        min-height: 200px;
        padding-bottom: 0px;
        margin: auto;
        height: 0px;
        h3 {
          font-size: 24px;
          margin-bottom: 15px;
          line-height: 30px;
        }
        h5 {
          font-size: 14px;
          font-weight: bold;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin-top: 0;
        }
      }
      li:last-of-type {
        width: 300px;
        height: 330px;
        position: relative;
        display: block;
        margin: 20px auto 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0px !important;
      left: 0 !important;
    }
    .swiper-pagination-bullet {
      width: 24px !important;
      height: 24px !important;
      font-size: 12px !important;
      line-height: 26px !important;
    }
  }
`;
