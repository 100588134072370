import { css } from '@emotion/react';

export const section7Style = css`
  background: #3e4454 !important;
  width: 100%;
  padding: 280px 0px;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    div:first-of-type {
      width: 630px;
      position: absolute;
      left: -80px;
      top: -80px;
      img {
        width: 100%;
      }
    }
    div:last-of-type {
      color: #fff;
      width: 50%;
      margin-right: -870px;
      h3 {
        font-size: 48px;
        line-height: 62px;
        font-weight: bold;
      }
      p {
        font-size: 19px;
        padding-top: 5%;
        line-height: 35px;
        padding-bottom: 5%;
      }
      div {
        position: inherit;
        margin-top: 50px;
        width: 430px;
        img {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 80px 0px;
    .container {
      width: 100%;
      display: block;
      div:last-of-type {
        margin: 0;
        width: 100%;
        div {
          margin: 60px auto 0;
          width: 300px;
          img {
            width: 100%;
          }
        }
        h3 {
          font-size: 24px;
          margin-top: 15px;
          margin-bottom: 15px;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin-top: 0;
          padding: 0;
        }
      }
    }
  }
`;
