/** @jsxImportSource @emotion/react */
import { section1Style } from '../../Home/Section1/styled';

const MoneSection5 = () => {
  return (
    <div css={section1Style}>
      <div className="container">
        <h2>신규 수익원 확보</h2>
        <p>
          보유한 트래픽을 활용한 새로운 수익 모델을 제시합니다.
          <br />
          새로운 혜택을 제공하여 사용자 만족도를 동시에
          <br className="m_con" /> 높일 수 있습니다.
        </p>
        <div>
          <img
            src="images/pc/img2-8.png"
            alt="content "
            title="content image"
            className="pc_con"
          />
          <img
            src="images/mobile/img2-8.png"
            alt="content "
            title="content image"
            className="m_con"
          />
          <img
            src="images/mobile/img2-9.png"
            alt="content "
            title="content image"
            className="m_con"
          />
        </div>
      </div>
    </div>
  );
};

export default MoneSection5;
