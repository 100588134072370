/** @jsxImportSource @emotion/react */
import { mainStyle, mobilMainStyle } from './styled';
import { useInquiry } from '../../../store/useInquiry';

interface mainProps {
  bg: string;
  title: string;
  m_title: string;
  m_bg: string;
}

const Main = (data: mainProps) => {
  const { bg, title, m_title, m_bg } = data;
  const { openPopup } = useInquiry((state) => state); // 팝업 전역변수
  return (
    <div css={mainStyle}>
      <div style={{ backgroundImage: `url(${bg})` }} className=" pc_con">
        <div className="container">
          <img src={title} alt="main title" title="main title" />
          <button type="button" onClick={openPopup}>
            <img src="images/pc/button.png" alt="button" title="button" />
          </button>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${m_bg})` }}
        className="m_con"
        css={mobilMainStyle}
      >
        <div className="container">
          <img src={m_title} alt="main title" title="main title" />
          <p>
            모든 매체에 쇼핑을 담다.
            <br />
            새로운 출발, 든든한 길잡이가 되겠습니다.
          </p>
          <button type="button" onClick={openPopup}>
            <img src="images/pc/button.png" alt="button" title="button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Main;
